import { regex, haMoment, DateOperator } from '@ha/helpers'
import { extend, setInteractionMode } from 'vee-validate'
import { alpha_num, required, email, digits } from 'vee-validate/dist/rules' // eslint-disable-line camelcase
import { isValid } from 'iban'
import { isInteger, toNumber, replace, toString, isFinite, compact } from 'lodash-es'
import { humanFileSize } from '@/helpers/misc'

export default function ({ app }) {
  setInteractionMode('eager')

  /**********
   *  Common
   **********/

  extend('required', {
    ...required,
    message: app.i18n.t('errors.required')
  })

  extend('equal', {
    params: ['target'],
    validate(value, { target }) {
      return value === target
    }
  })

  extend('taken', {
    params: ['existing'],
    validate: (value, { existing }) => {
      return Array.isArray(existing)
        ? existing.indexOf(value.toUpperCase()) === -1
        : existing !== value.toUpperCase()
    },
    message: app.i18n.t('errors.isTaken')
  })

  extend('email_taken', {
    params: ['existing'],
    validate: (value, { existing }) => {
      /* If existing is an array with a single element (= [string]), veeValidate transforms it into a string */
      return Array.isArray(existing)
        ? !existing.find((item) => item.toLowerCase() === value.toLowerCase())
        : existing.toLowerCase() !== value.toLowerCase()
    },
    message: app.i18n.t('errors.isEmailTaken')
  })

  extend('file_type', {
    params: ['accept'],
    validate: (value, { accept }) => {
      const mimeType = value.type
      return accept.some((type) => {
        const re = new RegExp('^' + type.replace('*', '.*') + '$')
        return re.test(mimeType)
      })
    },
    message: app.i18n.t('errors.fileType')
  })

  extend('file_max_size', {
    params: ['maxSize'],
    validate: (value, { maxSize }) => {
      return value.size <= maxSize
    },
    message: (fieldName, placeholders) => {
      return app.i18n.t('errors.maxFileSize', [humanFileSize(placeholders.maxSize)])
    }
  })

  extend('min_length', {
    params: ['array', 'size'],
    validate: (value, { array, size }) => {
      return compact(array).length >= size
    },
    message: (fieldName, placeholders) => {
      return app.i18n.t('errors.minLength', [placeholders.size])
    }
  })

  extend('max_length', {
    params: ['size'],
    validate: (value, { size }) => {
      return value?.length <= size
    },
    message: (fieldName, placeholders) => {
      return app.i18n.t('errors.maxLength', [placeholders.size])
    }
  })

  extend('min_characters', {
    params: ['size'],
    validate: (value, { size }) => {
      return value?.length >= size
    },
    message: (fieldName, placeholders) => {
      return app.i18n.t('errors.min', [placeholders.size])
    }
  })

  /**********
   *  Date
   **********/

  extend('date_valid', {
    validate: (value) => {
      if (!value) return true
      return haMoment(value).isValid()
    },
    message: app.i18n.t('errors.invalidDate')
  })

  extend('date_before', {
    params: ['minDate'],
    validate: (value, { minDate }) => {
      if (!value || !minDate) return true
      return haMoment(value).isSameOrBefore(minDate)
    },
    message: app.i18n.t('errors.startDateNotBeforeEndDate')
  })

  extend('date_after', {
    params: ['maxDate'],
    validate: (value, { maxDate }) => {
      if (!value || !maxDate) return true
      return haMoment(value).isSameOrAfter(maxDate)
    },
    message: app.i18n.t('errors.endDateNotAfterStartDate')
  })

  extend('date_after_strict', {
    params: ['maxDate'],
    validate: (value, { maxDate }) => {
      if (!value || !maxDate) return true
      return new DateOperator(value).isAfter(maxDate, 'days')
    },
    message: app.i18n.t('errors.endDateNotAfterStartDate')
  })

  extend('date_after_today', {
    params: ['maxDate'],
    validate: (value) => {
      if (!value) return true
      return haMoment(value).startOf('day').isSameOrAfter(haMoment().startOf('day'))
    },
    message: app.i18n.t('errors.endDateNotAfterToday')
  })

  extend('time_is_after', {
    params: ['minTime'],
    validate: (value, { minTime }) => {
      if (!value || !minTime) return true
      return value.isAfter(minTime, 'second')
    },
    message: app.i18n.t('errors.mustBeInTwoDay')
  })

  extend('date_string_format', {
    validate: (value) => {
      return regex.date.test(value)
    },
    message: app.i18n.t('errors.dateStringFormat')
  })

  extend('above18', {
    validate: (value) => {
      const majorityDate = haMoment().subtract(18, 'years')
      const date = haMoment(value, 'DD/MM/YYYY').format()

      return haMoment(date).isBefore(majorityDate)
    },
    message: app.i18n.t('errors.above18')
  })

  extend('date_is_after_sale_start', {
    params: ['startSaleDate'],
    validate: (value, { startSaleDate }) => {
      if (!value || !startSaleDate) return true
      return new DateOperator(value).isAfter(startSaleDate)
    },
    message: (fileName, placeholders) => {
      return app.i18n.t('errors.startDateIsAfterStartSale', [
        new DateOperator(placeholders.startSaleDate).format('DD/MM/YYYY')
      ])
    }
  })

  extend('date_is_after_sale_end', {
    params: ['endSaleDate'],
    validate: (value, { endSaleDate }) => {
      if (!value || !endSaleDate) return true
      return new DateOperator(value).isAfter(endSaleDate)
    },
    message: (fileName, placeholders) => {
      return app.i18n.t('errors.endDateIsAfterEndSale', [
        new DateOperator(placeholders.endSaleDate).format('DD/MM/YYYY')
      ])
    }
  })

  extend('time_is_after_sale_start', {
    params: ['startSaleTime'],
    validate: (value, { startSaleTime }) => {
      if (!value || !startSaleTime) return true
      const time = new DateOperator(value).format('HH:mm')

      return time >= startSaleTime
    },
    message: (fileName, placeholders) => {
      return app.i18n.t('errors.startTimeIsAfterStartSale', [placeholders.startSaleTime])
    }
  })

  extend('time_is_after_sale_end', {
    params: ['endSaleTime'],
    validate: (value, { endSaleTime }) => {
      if (!value || !endSaleTime) return true
      const time = new DateOperator(value).format('HH:mm')

      return time >= endSaleTime
    },
    message: (fileName, placeholders) => {
      return app.i18n.t('errors.startTimeIsAfterEndSale', [placeholders.endSaleTime])
    }
  })

  extend('date_before_1900', {
    validate: (value) => {
      if (!value) return true

      const date = haMoment(value, 'DD/MM/YYYY').format()
      const minDate = haMoment('01/01/1900', 'DD/MM/YYYY')

      return haMoment(date).isSameOrAfter(minDate)
    },
    message: app.i18n.t('errors.dateBefore1900')
  })

  /**********
   *  String
   **********/

  extend('one_alphanumeric', {
    validate: (value) => {
      if (!value) return true
      const regexTest = /.*[0-9].*|.*[a-zÀ-úA-Z].*/
      return regexTest.test(value)
    },
    message: app.i18n.t('errors.oneAlphaNumeric')
  })

  extend('email', {
    ...email,
    message: app.i18n.t('errors.invalidEmail')
  })

  extend('phone', {
    validate: (value) => {
      return regex.phone.test(value)
    },
    message: app.i18n.t('errors.invalidPhone')
  })

  extend('zipCode', {
    validate: (value) => {
      return regex.zipCode.test(value)
    },
    message: app.i18n.t('errors.zipCode')
  })
  extend('city', {
    validate: (value) => {
      return regex.name.test(value)
    },
    message: app.i18n.t('errors.city')
  })

  extend('coordinates', {
    validate: (value) => {
      return regex.coordinates.test(value)
    },
    message: app.i18n.t('errors.coordinates')
  })

  extend('hexadecimal_color', {
    validate: (value) => {
      return regex.hexaColor.test(value)
    },
    message: app.i18n.t('errors.invalidHexadecimalColor')
  })

  extend('url', {
    validate: (value) => {
      return regex.url.test(value)
    },
    message: app.i18n.t('errors.invalidUrl')
  })

  extend('rna', {
    validate: (value) => {
      if (!value) return true
      const regexTest = /^W[0-9]{9}/
      const regexTest2 = /^W(([0-9][CGMNRSTF])|([2][AB]))[0-9]{7}/
      return regexTest.test(value) || regexTest2.test(value)
    },
    message: app.i18n.t('errors.rna')
  })

  extend('iban', {
    validate: (value) => {
      return isValid(value)
    },
    message: app.i18n.t('errors.iban')
  })

  extend('bic', {
    validate: (value) => {
      return regex.bic.test(value)
    },
    message: app.i18n.t('errors.bic')
  })

  extend('latin_characters', {
    validate: (value) => {
      return regex.latinCharacters.test(value)
    },
    message: app.i18n.t('errors.latinCharacters')
  })

  extend('alphaNumDashApostrophe', {
    message: app.i18n.t('errors.alphaNumDashApostrophe'),
    validate: (value) => {
      // eslint-disable-next-line no-control-regex
      return regex.alphaNumDashApostrophe.test(value)
    }
  })

  //name or surname

  extend('alphabetic_name', {
    validate: (value) => {
      if (!value) return true
      const regexTest = /^[a-zÀ-úA-Z-'\s]*$/
      return regexTest.test(value)
    },
    message: app.i18n.t('errors.alphabetic')
  })

  extend('alpha_num', {
    // eslint-disable-next-line camelcase
    ...alpha_num,
    message: app.i18n.t('errors.alphabeticNum')
  })

  extend('one_vowel', {
    validate: (value) => {
      if (!value) return true
      const regexTest = /[aeiouyAEIOUY]/
      return regexTest.test(value)
    },
    message: app.i18n.t('errors.vowel')
  })

  extend('not_three_consecutive_characters', {
    validate: (value) => {
      if (!value) return true
      const regexTest = /([a-zÀ-úA-Z])\1{2}/
      return !regexTest.test(value)
    },
    message: app.i18n.t('errors.trigramme')
  })

  /**********
   *  Number
   **********/

  extend('number', {
    validate: (value) => {
      if (!value) return true
      return isFinite(toNumber(value))
    },
    message: app.i18n.t('errors.notNumber')
  })

  extend('positive', {
    validate: (value) => {
      if (!value) return true
      return toNumber(value) > 0
    },
    message: app.i18n.t('errors.notPositive')
  })

  extend('integer', {
    validate: (value) => {
      if (!value) return true
      return isInteger(toNumber(value))
    },
    message: app.i18n.t('errors.notInteger')
  })

  extend('percentage', {
    validate: (value) => {
      if (!value) return true
      return value <= 100
    },
    message: app.i18n.t('errors.notPercentage')
  })

  extend('decimal', {
    params: ['maxDecimals'],
    validate: (value, { maxDecimals }) => {
      if (!value) {
        return true
      }
      const sValue = replace(toString(value), ',', '.') // replace commas with colons
      const parts = sValue.split('.')
      if (!parts[1]) {
        return true // no decimals
      }
      if (parts.length > 2) {
        return false // more than one colon
      }
      return parts[1]?.length || 0 <= maxDecimals
    },
    message(fieldName, placeholders) {
      return app.i18n.t(
        `errors.notDecimal.${placeholders.maxDecimals > 1 ? 'plural' : 'singular'}`,
        [placeholders.maxDecimals]
      )
    }
  })

  extend('min', {
    params: ['minValue'],
    validate: (value, { minValue }) => {
      if (!value) return true
      return toNumber(value) >= minValue
    },
    message(fieldName, placeholders) {
      return `${app.i18n.t('errors.notGreaterOrEqual')} ${placeholders.minValue.replace('.', ',')}€`
    }
  })

  extend('zeroOrMinFiftyCents', {
    validate: (value) => {
      if (!value) return false
      return value == 0 || toNumber(value) >= 0.5
    },
    message() {
      return `${app.i18n.t('errors.notGreaterOrEqual')} 0,50€`
    }
  })

  extend('max', {
    params: ['maxValue'],
    validate: (value, { maxValue }) => {
      if (!value) return true
      return toNumber(value) <= maxValue
    },
    message(fieldName, placeholders) {
      return app.i18n.t('errors.notLesserOrEqual', [placeholders.maxValue])
    }
  })

  extend('digits', {
    ...digits,
    message(fieldName, placeholders) {
      return app.i18n.t('errors.digits.unequal', [placeholders.length])
    }
  })
}
